
import { Vue, Options } from 'vue-class-component';
import DefaultLayout from '@/lib/layouts/DefaultLayout.vue';
import {
  SidebarNavigation,
  SidebarNavigationGroup,
  SidebarNavigationLink,
  LegalPolicy,
  BaseSelectDropdown
} from '@/lib/components';
import { useSessionStore } from '@/stores/session.store';

@Options({
  components: {
    DefaultLayout,
    SidebarNavigation,
    SidebarNavigationGroup,
    SidebarNavigationLink,
    LegalPolicy,
    BaseSelectDropdown
  },
  props: {
    showLayout: {
      type: Boolean,
      default: true
    },
    routePrefix: {
      type: String,
      default: ''
    }
  }
})
export default class LegalPage extends Vue {
  routePrefix!: string;
  countryCode: string | null = null;
  policyType: string | null = null;
  selectedRegionId: string | null = null;
  sessionStore = useSessionStore();

  get branding() {
    return this.sessionStore.customerBranding;
  }

  get allLegalRegions(): string[] {
    return this.sessionStore.legalRegions;
  }

  get legalRegionsDropdownOptions() {
    return this.allLegalRegions.map((region) => ({
      value: region,
      label: this.$t(`platform.countries.${region.toUpperCase()}`)
    }));
  }

  created() {
    this.$watch('allLegalRegions', () => {
      this.selectedRegionId = this.allLegalRegions[0];
    }, {
      immediate: true
    });
  }

  mounted() {
    this.goToDefaultRoute(this.selectedRegionId || this.allLegalRegions[0]);
    this.selectedRegionWatcher();
    this.routeWatcher();
  }

  selectedRegionWatcher() {
    this.$watch('selectedRegionId', (newVal, oldVal) => {
      if (newVal !== oldVal) {
        this.goToDefaultRoute(newVal);
      }
    });
  }

  routeWatcher() {
    const unWatchRoute = this.$watch('$route', (to, from) => {
      if (from.name?.includes('legal') && to.name === `${this.routePrefix}legal` && to.path !== from.path) {
        this.goToDefaultRoute(this.allLegalRegions[0]);
      } else {
        unWatchRoute();
      }
    });
  }

  async goToDefaultRoute(region: string) {
    if (this.$route.params.region !== region) {
      await this.$router.push({ name: `${this.routePrefix}legal-privacy-policy`, params: { region } });
    }
  }
}
